export const localizeValue = (value: string | number) => {
  if (typeof value === "number") {
    return value.toLocaleString("en-US");
  }

  if (value.includes("%")) {
    const noPercent = value.replace("%", "");
    const formattedPercent = Number(noPercent).toLocaleString("en-US") + "%";
    return formattedPercent;
  }

  if (value.includes("+")) {
    const noPlus = value.replace("+", "");
    const formattedPlus = Number(noPlus).toLocaleString("en-US");
    return `+${formattedPlus}`;
  }
  
  if (value.includes("-")) {
    const noMinus = value.replace("-", "");
    const formattedMinus = Number(noMinus).toLocaleString("en-US");
    return `-${formattedMinus}`;
  }

  return value;
};
