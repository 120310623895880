// type ChartThemes = {
//   [key: string]: string[];
// };

type ChartThemes = {
  [key: string]: { colors: string[]; grid: string };
}
const chartColors: ChartThemes = {
  light: {colors: ["#FFB067", "#FF7077", "#ACEEF3"], grid: "#e3e3e3"},
  dark: {colors: ["#FFA384", "#EFE7BC", "#74BDCB"], grid: "#000000"},
  "catppuccin-mocha": {colors: ["#fab387", "#74c7ec", "#cba6f7", "#b4befe", "#a6e3a1"], grid: "#313244"},
};

export const getChartColors = () => {
  const theme = localStorage.getItem("vite-ui-theme") || "light";

  return chartColors[theme];
};
